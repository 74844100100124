<template>
   <div class="max-w-4xl mx-auto px-2 z-0  p-2 rounded">
       <form @submit.prevent="userLogin" >
            <div class="w-full md:grid md:grid-cols-12 gap-8  bg-white md:h-96 p-4 rounded-md">
                <div class="col-span-6 flex items-center">
                    <img src="../../../assets/login.gif"  />
                </div>
                <div class="col-span-6 flex flex-col justify-center gap-8  mt-8 md:mt-0">
                    <h2 class="text-brand text-2xl">{{ pageTitle }}</h2>
                    <div class="block w-full">
                         <input-text size="md" type="password" password-eye
                        v-model="password"
                        input-class="text-center"
                        class="flex-grow" />
                        <div class="text-red-500 md:text-xl">{{ $store.state.auth.errorMessage }}</div> 
                    </div>
                    <!-- <div class="w-full flex justify-start items-center">
                        <label for="sms_input" class="flex items-center gap-2 text-md md:text-xl hover:underline">
                            <input id="sms_input" type="checkbox" v-model="sms" class="hidden w-5 h-5" />
                            I don't remember my password
                        </label>
                     </div> -->
                     <div class="flex justify-center">
                        <button type="submit" class="bg-brand text-white py-1.5 px-2 rounded-md flex items-center"> 
                            <spinner v-if="loading" />
                             Login 
                        </button>
                     </div>
                </div>
            </div>
       </form>
   </div>
</template>

<script>
import Input from '../../../components/input-new.vue'
import Spinner from '../../../components/spinner.vue'
import InputText  from '../../../components/input-text.vue'
// import ShowError from "../../../components/show-error.vue";
export default {
   components:{
       Input,
       Spinner,
       InputText
   },
   data() {
    return {
        name:this.$route.query.name ||'',
        phone: this.$route.query.phone || '',
        loading: false,
        pageTitle: "Login Page",
        password: '',
        formMessage:''
    }
  },
  computed:{
    searchUser() {
            return this.$store.state.join.user;
        },
    user_info(){
       return this.$route.params.name;
     },
    loginIn(){
        return  this.$store.state.login.isLoggedIn;
    }

  },

created(){
    // this.$store.dispatch('login/findUser');
},
watch: {
    '$store.state.join.user'(data){
        this.phone = data.phone,
        this.name = data.name
    }
},

   methods:{
        userLogin(){
            this.loading = true;
            this.formErrors = [];

            if(this.phone.length == 11){
                const response = this.$store.dispatch("auth/login", {
                    data: { 
                        password: this.password,
                        phone: this.phone,
                    },
                }).then(  () => {
                     this.$toast.success(`You're Logged In!!!`,{
                        position: "top-right",
                        duration:3000
                    });
                    this.$router.push("/");
                })
                .finally( (  ) => {
                    this.loading = false
                });
            }
        },
   }
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>